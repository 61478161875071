import React, { Component } from 'react';


class Loading extends Component {
    componentDidMount() {
        
    }

    render() {
        return (
            <div id='loading'></div>
        )
    }
}
export default Loading