import React, { Component } from 'react';
import * as THREE from 'three';
import FBXLoader from 'three-fbxloader-offical'
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { HDRCubeTextureLoader } from 'three/examples/jsm/loaders/HDRCubeTextureLoader.js';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
import { RGBShiftShader } from 'three/examples/jsm/shaders/RGBShiftShader.js';
import { DotScreenShader } from 'three/examples/jsm/shaders/DotScreenShader.js';

class Intro extends Component {
    componentDidMount() {
        this.initThree();
    }
    scrolling(y) {
        // console.log(y);
        let clientHeight = window.innerHeight
        let clientWidth = window.innerWidth
        let introStartOffset = 400
        let KStartOffset = 430
        var objectInitialX = -120;
        var objectInitialY = -80;
        if (y < clientHeight * 1.4) {
            if (window.objectCover != undefined) window.objectCover.visible = true
            if (window.objectKentos != undefined) window.objectKentos.visible = false
            window.camera.position.z = introStartOffset + (y * 0.2);
            if (window.object != undefined) window.object.position.x = objectInitialX
            if (window.object != undefined) window.object.position.y = objectInitialY
        } else if (y >= clientHeight * 1) {
            if (window.objectCover != undefined) window.objectCover.visible = false
            if (window.objectKentos != undefined) window.objectKentos.visible = true
            // if (window.objectKentos != undefined) window.objectKentos.position.x = 00
            window.camera.position.z = clientWidth <= 868 ?  650 : KStartOffset
            if (window.object != undefined) window.object.position.x = 0
            if (window.object != undefined) window.object.position.y = 0
        }
    }
    initThree() {


        var container;

        var camera, scene, renderer, composer;
        var particleLight;

        var mouseX = 0, mouseY = 0;

        var windowHalfX = window.innerWidth / 2;
        var windowHalfY = window.innerHeight / 2;

        var object;
        var objectInitialZ = -80;
        var objectInitialX = -120;
        var cameraInitialZ = 400;
        var cameraInitialZMobile = 400;

        init();
        animate();
        function init() {

            container = document.createElement('div');
            container.style = `
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
        height: 100%;
        background: none;
        z-index: 9;
	`;
            document.getElementById('intro-ball').appendChild(container);

            window.camera = camera = new THREE.PerspectiveCamera(39, window.innerWidth / window.innerHeight, 1, 2000);
            updateCameraPosition();

            // scene
            var path = 'https://cdn.mionapp.com/website/ec/images/indoor/';
            var format = '.png';
            var urls = [
                path + 'px' + format, path + 'nx' + format,
                path + 'py' + format, path + 'ny' + format,
                path + 'pz' + format, path + 'nz' + format
            ];

            var reflectionCube = new THREE.CubeTextureLoader().load(urls);
            reflectionCube.format = THREE.RGBFormat;

            scene = new THREE.Scene();
            //scene.background = new THREE.Color().setHex(0xffe119);
            scene.fog = new THREE.Fog(new THREE.Color().setHex(0xEFBA00), 600, 1000);

            let ambientLight = new THREE.AmbientLight(0x626262);
            scene.add(ambientLight);

            var hemiLight = new THREE.HemisphereLight(0xffe119, 0xffe119, 0.6);
            hemiLight.color.setHex(0xffffff);
            hemiLight.groundColor.setHex(0xEFBA00);
            hemiLight.position.set(0, 150, 0);
            scene.add(hemiLight);

            var dirLight = new THREE.DirectionalLight(0xffffff, 1);
            dirLight.color.setHSL(0.1, 1, 0.95);
            dirLight.position.set(- 1, 1.75, 1);
            dirLight.position.multiplyScalar(30);
            scene.add(dirLight);
            dirLight.castShadow = true;


            scene.add(camera);




            // manager

            function loadModel() {
                if (object != undefined) {
                    object.traverse(function (child) {
                        if (child.isMesh) {
                            child.material = material;
                            child.geometry = (new THREE.Geometry()).fromBufferGeometry(child.geometry);
                            // console.log(child);
                            if (child.name == "cover") {
                                window.objectCover = child
                                child.visible = true
                            } else if (child.name == "kentos") {
                                window.objectKentos = child
                                child.visible = false //Default status
                            }
                        }
    
                        //
    
                    });
    
                    object.position.y = objectInitialZ;
                    object.position.x = objectInitialX;
                    window.object = object;
                    scene.add(window.object);
                }
                

            }

            var manager = new THREE.LoadingManager(loadModel);

            manager.onProgress = function (item, loaded, total) {

                console.log(item, loaded, total);

            };

            // texture

            var textureLoader = new THREE.TextureLoader(manager);

            var material = new THREE.MeshStandardMaterial({ color: 0xffe119, envMap: reflectionCube, metalness: 0.2, roughness: 0.3, diffuse: 0.9 });
            // var material =  new THREE.MeshBasicMaterial( { color: 0xffaa00, transparent: true, blending: THREE.AdditiveBlending } ) 
            // model

            function onProgress(xhr) {

                if (xhr.lengthComputable) {

                    var percentComplete = xhr.loaded / xhr.total * 100;
                    // console.log('model ' + Math.round(percentComplete, 2) + '% downloaded');

                }

            }

            function onError() { }

            var loader = new FBXLoader(manager);

            loader.load('https://cdn.mionapp.com/website/ec/ec-finnal.fbx', function (obj) {

                object = obj;

            }, onProgress, onError);

            //light start

            // particleLight = new THREE.Mesh(
            //     new THREE.SphereBufferGeometry( 0, 0, 0 ),
            //     new THREE.MeshBasicMaterial( { color: 0xffffff } )
            // );
            // scene.add( particleLight );

            // particleLight.add( new THREE.PointLight( 0xffffff, .2 ) );
            // particleLight.visible = true;
            // light end

            renderer = new THREE.WebGLRenderer({ antialias: false, alpha: true });
            renderer.setPixelRatio(window.devicePixelRatio);
            renderer.setSize(window.innerWidth, window.innerHeight);
            //renderer.toneMapping = THREE.ReinhardToneMapping;
            container.appendChild(renderer.domElement);

            document.addEventListener('mousemove', onDocumentMouseMove, false);

            //

            window.addEventListener('resize', onWindowResize, false);

            //posteffects

            //composer = new EffectComposer( renderer );
            //composer.addPass( new RenderPass( scene, camera ) );
            // var effect = new ShaderPass( DotScreenShader );
            // effect.uniforms[ 'scale' ].value = 59;
            // composer.addPass( effect );
            // var effect = new ShaderPass( RGBShiftShader );
            // effect.uniforms[ 'amount' ].value = 0.0015;
            // composer.addPass( effect );


            window.addEventListener("scroll", updateCamera);

        }
        function updateCamera(ev) {
            var offsetZ = window.innerWidth <= 700 ? cameraInitialZMobile : cameraInitialZ
            //object.position.y =  (window.innerWidth <= 700 ? -65 : 0) + (window.scrollY * 0.2);
            camera.position.z = offsetZ + (window.scrollY * 0.5);
        }

        function onWindowResize() {

            windowHalfX = window.innerWidth / 2;
            windowHalfY = window.innerHeight / 2;

            camera.aspect = window.innerWidth / window.innerHeight;
            camera.updateProjectionMatrix();

            renderer.setSize(window.innerWidth, window.innerHeight);
            //composer.setSize( window.innerWidth, window.innerHeight );
            //console.log(window.innerWidth);
            updateCameraPosition();

        }

        function updateCameraPosition() { // responce to window width
            if (window.innerWidth <= 700) {
                camera.position.z = cameraInitialZMobile;
            } else {
                camera.position.z = cameraInitialZ;
            }
        }

        function onDocumentMouseMove(event) {

            mouseX = (event.clientX - windowHalfX) / 30;
            mouseY = (event.clientY - windowHalfY) / 3;
            //camera.position.x = event.clientY 
        }

        //

        function animate() {

            requestAnimationFrame(animate);
            render();

        }

        function render() {
            // animate light
            var timer = Date.now() * 0.00025;

            // particleLight.position.x = Math.sin( timer * 7 ) * 300;
            // particleLight.position.y = Math.cos( timer * 5 ) * 400;
            // particleLight.position.z = Math.cos( timer * 3 ) * 300;

            for (var i = 0; i < scene.children.length; i++) {

                var child = scene.children[i];
                child.rotation.y += 0.005;
                if (child == object) {
                    child.rotation.x += 0.0025;
                    child.rotation.y += 0.0025;
                    child.rotation.z += 0.0025;
                }

            }
            // animate light

            camera.position.x += (mouseX - camera.position.x) * .05;
            camera.position.y += (- mouseY - camera.position.y) * .05;

            camera.lookAt(scene.position);

            renderer.render(scene, camera);
            // composer.render();

        }
    }
    render() {
        return (
            <div id='intro-ball'></div>
            
        )
    }
}
export default Intro