import React, { Component } from "react";
import detectBrowserLanguage from 'detect-browser-language'
import { Helmet } from "react-helmet";
import Intro from './intro.js';
import Loading from './loading.js';
import Circles from './circles.js';
import './style/app.sass';
import Navigation from './stack/Navigation';
import WOW from 'wowjs';
import LocomotiveScroll from 'locomotive-scroll';

//images
const cdn = "https://cdn.mionapp.com/website/ec/"
const modeMark = cdn + 'images/mode-mark.svg';
const iconCommunication = cdn + 'images/icon-about-communication.svg';
const iconSystem = cdn + 'images/icon-about-system.svg';
const iconCoreVisual = cdn + 'images/icon-visual.png';
const iconCoreHearing = cdn + 'images/icon-hearing.png';
const iconCoreSmell = cdn + 'images/icon-smell.png';
const iconCoreTouch = cdn + 'images/icon-touch.png';
const iconCorePrinciple = cdn + 'images/icon-principle.png';

const iconPrincipleCoop = cdn + 'images/icon-principle-coop.png';
const iconPrincipleFacts = cdn + 'images/icon-principle-facts.png';
const iconPrincipleDiversity = cdn + 'images/icon-principle-diversity.png';
const iconPrincipleAltruistic = cdn + 'images/icon-principle-altruistic.png';
const iconPrincipleIteration = cdn + 'images/icon-principle-iteration.png';
const iconPrincipleIntrospection = cdn + 'images/icon-principle-introspection.png';
const iconPrincipleFairness = cdn + 'images/icon-principle-fairness.png';
const iconPrincipleLearning = cdn + 'images/icon-principle-learning.png';
const cornerMark = cdn + 'images/corner-mark.svg';
const cornerMarkBlack = cdn + 'images/corner-mark-black.svg';
const iconPdfBlack = cdn + 'images/icon-pdf-black.svg';
const iconPdfTg = cdn + 'images/icon-pdf-tg.svg';
const iconPdf = cdn + 'images/icon-pdf.svg';

const picWorkMion = cdn + 'images/pic-work-mion.png';
const picWorkMionWeb = cdn + 'images/pic-work-mion-web.png';
const picWorkCd = cdn + 'images/pic-work-cd.png';
const picWorkTg = cdn + 'images/pic-work-tg.png';

const picWorkLs2 = cdn + 'images/pic-work-ls-2.png';
const picWorkHover = cdn + 'images/pic-work-hover.png';
const picWorkPhantom = cdn + 'images/pic-work-phantom.png';
const picWorkBearychat = cdn + 'images/pic-work-bearychat.png';
const picWorkBearysans = cdn + 'images/pic-work-bearysans.svg';
const picWorkPoe = cdn + 'images/pic-work-poe.png';
const picWorkOnestack = cdn + 'images/pic-work-onestack.png';
const picWorkMint = cdn + 'images/pic-work-mint.svg';

const picWorkTheorist = cdn + 'images/pic-work-theorist.png';
const picWorkPalor = cdn + 'images/pic-work-palor.png';
const picWorkMoneyback = cdn + 'images/pic-work-moneyback.png';
const picWorkSp = cdn + 'images/pic-work-sp.png';
const picWorkAtem = cdn + 'images/pic-work-atem.png';
const picWorkChapter = cdn + 'images/pic-work-chapter.png';

const picWorkMulcloud1 = cdn + 'images/pic-work-mulcloud-1.png';
const picWorkMulcloud2 = cdn + 'images/pic-work-mulcloud-2.png';
const picWorkMulcloud3 = cdn + 'images/pic-work-mulcloud-3.png';
const picWorkMulcloud4 = cdn + 'images/pic-work-mulcloud-4.png';
const picWorkMulcloud5 = cdn + 'images/pic-work-mulcloud-5.png';
const picWorkMulcloud6 = cdn + 'images/pic-work-mulcloud-6.png';

const picWorkKentos1 = cdn + 'images/pic-work-kentos-1.png';
const picWorkKentos2 = cdn + 'images/pic-work-kentos-2.png';

const picWorkExtra0 = cdn + 'images/pic-work-extra-0.png';
const picWorkExtra1 = cdn + 'images/pic-work-extra-1.png';
const picWorkExtra2 = cdn + 'images/pic-work-extra-2.png';
const picWorkExtra3 = cdn + 'images/pic-work-extra-3.png';
const picWorkExtra4 = cdn + 'images/pic-work-extra-4.png';
const picWorkDawn = cdn + 'images/pic-work-dawn.png';
const picWorkExtra5 = cdn + 'images/pic-work-extra-5.png';
const picWorkExtra6 = cdn + 'images/pic-work-extra-6.png';
const portfolioMock = cdn + 'images/portfolio-mock.jpg';

const iconTwitter = cdn + 'images/icon-sns-twitter.svg';
const iconLinkedIn = cdn + 'images/icon-sns-linkedin.svg';
const iconGithub = cdn + 'images/icon-sns-github.svg';
const iconSoundcloud = cdn + 'images/icon-sns-soundcloud.svg';
const iconYoutube = cdn + 'images/icon-sns-youtube.svg';




// import logoLuxu from 'https://cdn.mionapp.com/website/images/logo-luxu.svg';
// import secondPaint from 'https://cdn.mionapp.com/website/images/section-second-paint.png';
// import thirdPaint from 'https://cdn.mionapp.com/website/images/section-third-paint.png';
// import fourthPaint from 'https://cdn.mionapp.com/website/images/section-fourth-paint.png';
// import fifthPaint from 'https://cdn.mionapp.com/website/images/section-fifth-paint-iphone.png';
// import thirdIconCard from 'https://cdn.mionapp.com/website/images/section-third-icon-card.png';
// import thirdIconCast from 'https://cdn.mionapp.com/website/images/section-third-icon-cast.png';
// import thirdIconFilm from 'https://cdn.mionapp.com/website/images/section-third-icon-film.png';
// import logoMion from 'https://cdn.mionapp.com/website/images/logo-mion.svg';


class App extends Component {
  componentDidMount() {
    let _this = this;
    this.scroll = new LocomotiveScroll({
      el: document.body,
      smooth: true,
      smoothMobile: true,
      repeat: true,
      touchMultiplier: 3
    });
    window.addEventListener("resize", () => { });
    this.scroll.on('scroll', function (obj) {
      var currentHeight = obj.scroll.y;
      var entireHeight = obj.limit;
      var clientHeight = window.innerHeight;

      //document.getElementById("core-image-main").style.top = "-104vh";
      // console.log(currentHeight + " With " + clientHeight);
      if (currentHeight >= entireHeight - clientHeight) {
        _this.refs.nav.setCornerMarkTop()
      } else {
        _this.refs.nav.setCornerMarkDown()
      }
      _this.refs.nav.viewPercent(currentHeight, entireHeight)
      // this.setState({ scrollY: 111 })
      _this.refs.child.scrolling(obj.scroll.y) // 判断滚动 y
    });
    

  }
  render() {
    const wow = new WOW.WOW();
    wow.init();


    window.addEventListener(
      "touchmove",
      function (event) {
        if (event.scale !== 1) {
          event.preventDefault();
        }
      },
      { passive: false }
    );

    var lang = detectBrowserLanguage();
    var cn = "zh-CN";



    return (

      <main className="app o-content" className={lang == cn ? 'cn-typo' : ''} id="app">
        <div id="scroll-scrollbar">
		<div id="scroll-scrollbar-thumb"></div>
	</div>
        <header className="header wow fadeIn">
          < Navigation ref="nav" />
        </header>
        <Helmet>
          <title>Excited Cosmos</title>
        </Helmet>

        <Loading />

        <div className="background-overlay">
          <Intro ref="child" />
        </div>

        <section data-scroll-section className="intro" id="intro">
          <div className="background-cosmos">
            {/* <Cosmos/> */}
          </div>
          <div className="intro-main-wrapper">
            <div className='intro-main m-container o-container'>
              <div className="o-content intro-box">
                <div className="seal" data-scroll data-scroll-target=".intro-box" data-scroll-speed="-11" data-scroll-position="top" data-scroll-direction="vertical" >
                  <img src={modeMark} className="mode-mark" alt="" />
                </div>
                <div className="menu">
                  <ul data-scroll data-scroll-speed="-1" data-scroll-position="top" data-scroll-direction="vertical">
                    <li><a href="#second" data-scroll-to>About</a></li>
                    <li><a href="#fourth" data-scroll-to>Principle</a></li>
                    <li><a href="#fifth" data-scroll-to>Work</a></li>
                  </ul>
                </div>
                <div className="title"  >
                  <h1 data-scroll data-scroll-speed="1" data-scroll-position="top" data-scroll-direction="vertical">
                    Systematic thinking drives the innovation and openness of design
                    </h1>
                </div>
                <div>

                </div>
                <div className="introduction">

                  {/* <p data-scroll data-scroll-speed="2" data-scroll-position="top" data-scroll-direction="vertical">
                      I am a System and Communication Designer. I love story-telling.
                    </p> */}
                </div>
                <div className="manifesto">
                  <p data-scroll data-scroll-speed="0.3" data-scroll-position="top" data-scroll-direction="vertical" >
                    <span>I am <b>Li Xingyu</b>, an Interaction and Product Designer.</span>
                  </p>
                  <p className="selected" data-scroll data-scroll-speed="0.3" data-scroll-position="top" data-scroll-direction="vertical">
                    <span>Focus on the deep understanding of design, a designer fascinated by complex systems.</span>
                  </p>

                </div>
              </div>

            </div>

          </div>


        </section>

        <section data-scroll-section className="section-fix-padding" id="second">
          <div className="second-wrapper  m-container o-container">
            <div></div>
            <div className="pt-cell plane" data-vs-id="multiple-planes-vs" data-fs-id="multiple-planes-fs" data-scroll- data-scroll-speed="-1" data-scroll-position="top" data-scroll-direction="vertical">
              <img src={iconSystem} alt="" />

            </div>
            <div className="pt-cell" data-scroll- data-scroll-speed="-0.3" data-scroll-position="top" data-scroll-direction="vertical">
              <img src={iconCommunication} alt="" />

            </div>
            <div className="pt-cell-up" >
              <div data-scroll- data-scroll-speed="0.2" data-scroll-position="top" data-scroll-direction="vertical">
                <h3>
                  Who am I?
                </h3>
                {/* <p>
                  Communication and System Designer = Communication and Product/UXer
                </p> */}
                <p>
                  {/* System design can be interpreted as Product design, it has the capabilities of UX design, visual and motion design, user research and data analysis, architecture design, engineering and development. Use interdisciplinary thinking to understand the business value behind decision-making, and target solutions to build efficient and sustainable systems. */}

I am a systematic thinker, years of design career enriched my experience in corporate services, also makes me find out—the foundation of creating a virtuous cycle for an enterprise depends on a <b>diversity of insights</b> and <b>flexibility of innovation</b>. Which diversity not only provides a way to fix issues but also provide extensible solutions. I think the flexibility of innovation relies on continuous innovation of methodology, technology, collaboration and decision-making.
                </p>
              </div>

            </div >
            <div className="pt-cell" data-scroll- data-scroll-speed="-1" data-scroll-position="top" data-scroll-direction="vertical">
              <p> <b>Diversity</b><br />An integrated system is organic. Building an organic system with diverse methods and toolkits is the key to maintaining the vitality.</p>

            </div>
            <div className="pt-cell" data-scroll- data-scroll-speed="-0.3" data-scroll-position="top" data-scroll-direction="vertical">
              <p><b>Flexibility</b><br />An organic system has universal commonality. It can be continuously imitated and spread, delivering irreplaceable value.</p>

            </div>
            <div>
              <Circles />
            </div>
            <div className="ability-info">
              <div>
                <h2 className="numbers">9</h2>
                <p className="subtext" >Years</p>
              </div>
              <div>
                <h2 className="numbers">2</h2>
                <p className="subtext" >Indie-Apps</p>
              </div>
              <div>
                <h2 className="numbers">4</h2>
                <p className="subtext" >Type Faces</p>
              </div>
              <div>
                <p className="career-text">
                  Building systematic design method and strategy. Design Management, Creative Direction & Development are my specialities.
                  </p>

              </div>
              <div>
                <h2 className="numbers">3</h2>
                <p className="subtext" >Design Systems</p>
              </div>
              <div>
                <h2 className="numbers">20 <sup>+</sup></h2>
                <p className="subtext" >Commercial Projects</p>
              </div>
            </div>
          </div>
        </section>

        <section data-scroll-section className="section-fix-padding" id="third">
          <div className="third-wrapper   m-container o-container">
            <div className="core-column">
              <div className="plane" data-vs-id="multiple-planes-vs" data-fs-id="multiple-planes-fs">
                <img src={iconCoreVisual} alt="" />
                <p>
                  <b>Sensory Level: Visual<br />System Level: Discipline</b><br />

                  Vision is the most frequently used sensory, in systems, it is the discipline: the initial source of perceptions.
                </p>
              </div>
              <div></div>
              <div><img src={iconCoreSmell} alt="" />
                <p>
                  <b>Sensory Level: Smell and Taste<br />System Level: Aesthetics</b><br />

                  Taste and smell is the easiest way to retrieve episodic memory, in systems, it is aesthetic: retrieve the emotions and memories of ones.
                </p></div>
              <div></div>
              <div>
                <img src={iconCorePrinciple} alt="" />
                <p>
                  <b>Sensory Level: Principle <br />System Level: Business</b><br />

                  Everything has its development mode and rules, in systems, it is business: the process of combining laws and exchanges.
                </p>
              </div>
            </div>
            <div className="core-column">
              <div></div>
              <div>
                <img src={iconCoreHearing} alt="" />
                <p>
                  <b>Sensory Level: Hearing<br />System Level: Empathy</b><br />

                  Hearing enriches and continuously reinforces the sensory experience, in systems, it is empathy: paying attention and listening to the sounds from the environment.
                </p>
              </div>
              <div></div>
              <div>
                <img src={iconCoreTouch} alt="" />
                <p>
                  <b>Sensory Level: Touch<br />System Level: Ethics</b><br />

                  Touch is the sensory that interacts with boundaries between subject and object, in systems, it is ethical: continually evaluating and balancing with other systems.
                </p>
              </div>
              <div></div>
            </div>

            <div>
              <div className="fixed-wrapper" data-scroll data-scroll-call="dynamicBackground" data-scroll-repeat>
                <div className="e-fixed" id="fixed-target"></div>
                <div className="core-image" id="core-image-main" data-scroll data-scroll-sticky data-scroll-target="#fixed-target"></div>
              </div>

            </div>

          </div>
        </section>

        <section data-scroll-section className="section-fix-padding" id="fourth">
          <div className="fourth-wrapper m-container o-container">
            <div className="cell">
              <div>
                <img src={iconPrincipleCoop} alt="" />
              </div>
              <div>
                <b>Collaboration</b>
                <p className="context">
                  The best team collaboration practice is goes deep into beliefs. Achieve the entirety with others in cooperation, Willing to undertake responsibilities.
                </p>
              </div>
            </div>

            <div className="cell">
              <div>
                <img src={iconPrincipleIteration} alt="" />
              </div>
              <div>
                <b>Continuous growth and iteration</b>
                <p className="context">

                  Be good at identifying mistakes and growth, treating them equally. Learn from mistakes, keep an open mind, and be optimistic. Iterate and growth.
                </p>
              </div>
            </div>

            <div className="cell">
              <div>
                <img src={iconPrincipleFacts} alt="" />
              </div>
              <div>
                <b>Facing the entirety and the facts</b>
                <p className="context">

                  Always facing the entirety is the core of Systematic Thinking,  Facing the fact of the world, not the certainty of the world, because most of the certainty can be whitewashed.                </p>
              </div>
            </div>

            <div className="cell">
              <div>
                <img src={iconPrincipleIntrospection} alt="" />
              </div>
              <div>
                <b>Introspection and self-driven</b>
                <p className="context">

                  It is not easy to change the environment and others. We have to keep introspecting so that we can keep being innovative. Always keep a clear and vivid mind.                </p>
              </div>
            </div>

            <div className="cell">
              <div>
                <img src={iconPrincipleDiversity} alt="" />
              </div>
              <div>
                <b>Diversity, open-minded, inclusive</b>
                <p className="context">

                  Being curious, observant, wiling to embrace the unknown and uncertainty, these are the basis for respecting various cultures, respecting differences, and being open-minded.                </p>
              </div>
            </div>

            <div className="cell">
              <div>
                <img src={iconPrincipleFairness} alt="" />
              </div>
              <div>
                <b>Fairness and respect</b>
                <p className="context">

                  Keep passionate and organized, respect the facts. Constantly innovate cognitions and evaluate, create with these manners.                </p>
              </div>
            </div>

            <div className="cell">
              <div>
                <img src={iconPrincipleAltruistic} alt="" />
              </div>
              <div>
                <b>Altruistic</b>
                <p className="context">

                  Understand that the resources we are using are from others' dedication of sharing. Being altruistic and convey the spirit.                </p>
              </div>
            </div>

            <div className="cell">
              <div>
                <img src={iconPrincipleLearning} alt="" />
              </div>
              <div>
                <b>Learning for process and fact</b>
                <p className="context">
                  Create and learn not just for convenience and functionality. Create and learn for helping others experience the whole process of life. Create and learn for the facts.                </p>
              </div>
            </div>
          </div>
        </section>

        <section data-scroll-section className="section-fix-padding" id="motto">
          <div className="motto-wrapper m-container o-container padding-wrapper">
            <div className="motto-cell one">
              <span className="direction" data-scroll data-scroll-direction="horizontal" data-scroll-speed="23" data-scroll-target="#motto">
                Design is a texture of values and relations.
                </span>
            </div>
            <div className="motto-cell two">
              <span className="direction" data-scroll data-scroll-direction="horizontal" data-scroll-speed="-20" data-scroll-target="#motto">
                Design is a texture of values and relations.
                </span>
            </div>

            <div className="motto-cell three">
              <span className="direction" data-scroll data-scroll-direction="horizontal" data-scroll-speed="12" data-scroll-target="#motto">
                Design is a texture of values and relations.
                </span>
            </div>

            <div className="motto-cell four">
              <span className="direction" data-scroll data-scroll-direction="horizontal" data-scroll-speed="-9" data-scroll-target="#motto">
                Design is a texture of values and relations.
                </span>
            </div>


          </div>
        </section>

        <section data-scroll-section className="section-fix-padding" id="fifth">
          <div className="fifth-wrapper m-container o-container">
            <div className="work-cell">
              <div>
                <a href="https://mionapp.com" target="_blank"></a>
                <h4>
                  Mionapp.com
                </h4>
                <p>
                  Website Design
                </p>
                <img src={cornerMark} className="link" alt="" />
                {/* <div className="background" style={{ backgroundImage: `url(${picWorkMion})` }}></div> */}
                <img className="main" src={picWorkMionWeb} alt="" />
              </div>
            </div>
            <div className="work-cell">
              <a href="https://apps.apple.com/us/app/mion-sleep/id1401308399" target="_blank"></a>
              <div>
                <h4>
                  Mion
                </h4>
                <p>
                  Indie-Application
                </p>
                <img src={cornerMark} className="link" alt="" />
                <img className="main" src={picWorkMion} alt="" />
              </div>
            </div>

            <div className="work-cell">
              <a href="https://design.teambition.com" target="_blank"></a>
              <div>
                <h4>
                  Teambition - Clarity Design
                </h4>
                <p>
                  Design System
                </p>
                <img src={cornerMark} className="link" alt="" />
                <img className="main" src={picWorkCd} alt="" />
              </div>
            </div>

            <div className="work-cell">
              <a href="https://cdn.mionapp.com/website/ec/pdf/TeambitionGrotesk.pdf" target="_blank"></a>
              <div>
                <h4>
                  Teambition Grotesk
                </h4>
                <p>
                  Typography
                </p>
                <img src={iconPdfTg} className="link pdf" alt="" />
                <img className="main" src={picWorkTg} alt="" />
              </div>
            </div>

            <div className="work-cell">
              <div>

                <img className="main" src={picWorkMulcloud1} alt="" />
              </div>
            </div>

            <div className="work-cell">
              <div>
                <img className="main" src={picWorkMulcloud2} alt="" />
              </div>
            </div>

            <div className="work-cell">
              <div>
                <img className="main" src={picWorkMulcloud3} alt="" />
              </div>
            </div>

            <div className="work-cell">
              <div>
                <img className="main" src={picWorkMulcloud5} alt="" />
              </div>
            </div>

            <div className="work-cell fit-image">
              <div>
                <h4>
                  MulCloud
                </h4>
                <p>
                  Brand Design System
                </p>
                <img className="main" src={picWorkMulcloud6} alt="" />
              </div>
            </div>

            <div className="work-cell">
              <div>
                <img className="main" src={picWorkMulcloud4} alt="" />
              </div>
            </div>
            <div className="work-cell">
              <div>
                <h4>
                  Kentos
                </h4>
                <p>
                  Typography
                </p>

                <img className="main" src={picWorkKentos2} alt="" />
              </div>
            </div>
            <div className="work-cell  fit-image">
            <a href="https://cdn.mionapp.com/website/ec/pdf/Kentos.pdf" target="_blank"></a>
              <div>
                <h4>
                  Kentos Specimen Book
                </h4>
                <p>
                  Typography
                </p>
                <img src={iconPdfBlack} className="link pdf" alt="" />
                <img className="main" src={picWorkKentos1} alt="" />
              </div>
            </div>



            <div className="work-cell">
              <div>
                <h4>
                  L Square
                </h4>
                <p>
                  Brand
                </p>
                <img className="main" src={picWorkLs2} alt="" />
              </div>
            </div>

            <div className="work-cell">
              <div>
                <h4>
                  Hover
                </h4>
                <p>
                  Product/UX
                </p>
                <img className="main" src={picWorkHover} alt="" />
              </div>
            </div>

            <div className="work-cell">
              <div>
                <h4>
                  Phantom
                </h4>
                <p>
                  Product/UX
                </p>
                <img className="main" src={picWorkPhantom} alt="" />
              </div>
            </div>

            <div className="work-cell">
              <a href="https://bearychat.com" target="_blank"></a>
              <div>
                <h4>
                  BearyChat
                </h4>
                <p>
                  Brand/UX
                </p>
                <img src={cornerMark} className="link" alt="" />
                <img className="main" src={picWorkBearychat} alt="" />
              </div>
            </div>


            <div className="work-cell">
              <div>
                <h4>
                  Beary Sans
                </h4>
                <p>
                  Typography
                </p>
                <img className="main" src={picWorkBearysans} alt="" />
              </div>
            </div>

            <div className="work-cell">
              <a href="https://apps.apple.com/app/poe/id793069272" target="_blank"></a>
              <div>
                <h4>
                  POE
                </h4>
                <p>
                  Indie-Application
                </p>
                <img src={cornerMarkBlack} className="link" alt="" />
                <img className="main" src={picWorkPoe} alt="" />
              </div>
            </div>

            <div className="work-cell">
              <div>
                <h4>
                  Onestack
                </h4>
                <p>
                  Indie-Application
                </p>
                <img className="main" src={picWorkOnestack} alt="" />
              </div>
            </div>

            <div className="work-cell">
              <div>
                <h4>
                  Mint
                </h4>
                <p>
                  Product Design
                </p>
                <img className="main" src={picWorkMint} alt="" />
              </div>
            </div>

            <div className="work-cell  fit-image">
              <div>
                <h4>
                  Moneyback
                </h4>
                <p>
                  Product Design
                </p>
                <img className="main" src={picWorkMoneyback} alt="" />
              </div>
            </div>

            <div className="work-cell  fit-image">
              <div>
                <h4>
                  Theorist Serif
                </h4>
                <p>
                  Typography
                </p>
                <img className="main" src={picWorkTheorist} alt="" />
              </div>
            </div>

            <div className="work-cell">
              <div>
                <h4>
                  Palor
                </h4>
                <p>
                  Extension/Plugin
                </p>
                <img className="main" src={picWorkPalor} alt="" />
              </div>
            </div>

            <div className="work-cell fit-image">
              <div>
                <h4>
                  Plant Kit
                </h4>
                <p>
                  Product Design
                </p>
                <img className="main" src={picWorkSp} alt="" />
              </div>
            </div>

            <div className="work-cell">
              <div>
                <h4>
                  Chapter
                </h4>
                <p>
                  Product/Fragrance Design
                </p>
                <img className="main" src={picWorkChapter} alt="" />
              </div>
            </div>

            <div className="work-cell">
              <div>
                <h4>
                  Atem
                </h4>
                <p>
                  Product Design
                </p>
                <img className="main" src={picWorkAtem} alt="" />
              </div>
            </div>

            <div className="work-cell fit-image">
              <div>
                <h4>
                  Earth
                </h4>
                <p>
                  Rendering
                </p>
                <img className="main" src={picWorkExtra0} alt="" />
              </div>
            </div>

            <div className="work-cell fit-image">
              <div>
                <h4>
                  Abstract Sculpture
                </h4>
                <p>
                  Rendering
                </p>
                <img className="main" src={picWorkExtra1} alt="" />
              </div>
            </div>

            <div className="work-cell fit-image">
              <div>
                <h4>
                  Geometry
                </h4>
                <p>
                  Rendering
                </p>
                <img className="main" src={picWorkExtra2} alt="" />
              </div>
            </div>

            <div className="work-cell fit-image">
              <div>
                <h4>
                  Geometry
                </h4>
                <p>
                  Rendering
                </p>
                <img className="main" src={picWorkExtra3} alt="" />
              </div>
            </div>

            <div className="work-cell fit-image">
              <div>
                <h4>
                  Hugclub
                </h4>
                <p>
                  Rendering
                </p>
                <img className="main" src={picWorkExtra4} alt="" />
              </div>
            </div>

            <div className="work-cell fit-image">
              <div>
                <h4>
                  Dawn
                </h4>
                <p>
                  Brand
                </p>
                <img className="main" src={picWorkDawn} alt="" />
              </div>
            </div>

            <div className="work-cell fit-image">
              <div>
                <h4>
                  Geometry
                </h4>
                <p>
                  Rendering
                </p>
                <img className="main" src={picWorkExtra5} alt="" />
              </div>
            </div>

            <div className="work-cell fit-image">
              <div>
                <h4>
                  Color
                </h4>
                <p>
                  Extension/Plugin
                </p>
                <img className="main" src={picWorkExtra6} alt="" />
              </div>
            </div>

            <div className="work-cell fit-image">
              <div>
                <h4>
                  Excited Cosmos Portfolio
                </h4>
                <span>
                  Contact me for the full version
                </span>
                <p>
                  Book Design
                </p>
                <img className="main" src={portfolioMock} alt="" />
              </div>
            </div>


          </div>
        </section>

        <section data-scroll-section className="section-fix-padding" id="footer">
          <div className="footer-wrapper m-container o-container padding-wrapper">
            <div className="footer-cell">
              <p>Excited <sup>SH</sup> <br /> Cosmos</p>
            </div>
            <div className="footer-cell">
              <ul>
                <li><a href="#second" data-scroll-to>About</a></li>
                <li><a href="#fourth" data-scroll-to>Principle</a></li>
                <li><a href="#fifth" data-scroll-to>Work</a></li>
              </ul>
            </div>

            <div className="footer-cell">
              <ul>
                <li><b>Contact</b></li>
                <li><a target="_blank" href="mailto:excitedcosmos@gmail.com">excitedcosmos@gmail.com</a></li>
                <li>+86 158 8201 2792</li>

                <li>
                  <ul id="sns">
                    <li><a href="https://twitter.com/CosmosExcited" target="_blank"><img src={iconTwitter} alt="https://twitter.com/CosmosExcited" /></a></li>
                    <li><a href="https://github.com/excitedcosmos" target="_blank"><img src={iconGithub} alt="https://github.com/excitedcosmos" /></a></li>
                    <li><a href="https://www.linkedin.com/in/excitedcosmos" target="_blank"><img src={iconLinkedIn} alt="www.linkedin.com/in/excitedcosmos" /></a></li>
                    <li><a href="https://soundcloud.com/pnecas" target="_blank"><img src={iconSoundcloud} alt="https://soundcloud.com/pnecas" /></a></li>
                    <li><a href="http://tiny.cc/excitedcosmos" target="_blank"><img src={iconYoutube} alt="http://tiny.cc/excitedcosmos" /></a></li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </section>

        <div className="c-scrollbar"><div className="c-scrollbar_thumb"></div></div>
      </main>
    );
  }
}

export default App;
