import React, { Component } from 'react';
import CirclePacker from 'circlepacker';
import './style/circles.sass';

class Circles extends Component {
    componentDidMount() {
        var duration = 1000
        var tagIndex = 0
        var tag = [
            "System",
            "Graphic",
            "Middle-End",
            "Devel-opment",
            "Inter-action",
            "3D",
            "React",
            "Odor",
            "Sound",
            "AGI",
            "HCD",
            "Product",
            "Brand",
            "UX/UI",
            "HCD",
            "D3",
            "Swift",
            "C#",
            "Arduino",
            "Adobe CEP",
            "Objective-C",
            "Java-Script",
            "Python",
            "Ruby",
            "Generative",
            "PHP",
            "C/C++",
            "Animation",
            "Video Making",
            "Typeface",
            "GLSL",
            "Web Design",
            "Art Direction",
            

            "Vue",
            "System",
            "Sensory",
            "Music",
            "Data Visulization",
            "Fragrance",
            "WebGL",
            "Ethics",
            "Iconology",
            "Semiotics",
            "Front-End",
            "Typography",
            "Motion",
            "Unity",
            "Extensions",
            "ES6",
            "Nuxt",
            "NodeJS",
            "Flutter",
            "Aesthetic"
        ];
        var DRAG_THRESOLD = 10;
        var containerEl = document.querySelector('#circle-box')
        var circleEls = {}
        var rect = containerEl.getBoundingClientRect();
        var bounds = { width: rect.width, height: rect.height };
        var target = { x: bounds.width / 2, y: bounds.height / 2 };

        var isDragging = false;

        var circles = [
            createCircle(tag[0]),
            createCircle(tag[1]),
            createCircle(tag[2]),
            createCircle(tag[3]),
            createCircle(tag[4]),
            createCircle(tag[5]),
            createCircle(tag[6]),
            createCircle(tag[7]),
            createCircle(tag[8]),
            createCircle(tag[9]),
            createCircle(tag[10]),
            createCircle(tag[11]),
            createCircle(tag[12]),
            createCircle(tag[13]),
            createCircle(tag[14]),
            createCircle(tag[15]),
            createCircle(tag[16]),
            createCircle(tag[17]),
            createCircle(tag[18]),
            createCircle(tag[19]),
            createCircle(tag[20]),
            createCircle(tag[21]),
            createCircle(tag[22]),
            createCircle(tag[23]),
            createCircle(tag[24]),
            createCircle(tag[25]),
            createCircle(tag[26]),
            createCircle(tag[27]),
            createCircle(tag[28]),
            createCircle(tag[29]),
            createCircle(tag[30]),
            
        ];

        var packer = new CirclePacker({
            bounds: bounds,
            target: target,
            circles: circles,
            onMove: render,
            collisionPasses: 3,
            centeringPasses: 2
        });
        init();
        function init(){
            var tagLength = tag.length
            
            setDamping() 
            setInterval(function () {
                try {
                    removeRandomCircle();
                    addRandomCircle();
                    if (tagIndex < tagLength - 1) {
                        tagIndex += 1;
                    }else{
                        tagIndex = 0;
                    }
                    
                } catch (error) {
                    console.log(error)
                }
                
            }, duration);
        }

        function addRandomCircle() {
           packer.addCircle(createCircle(tag[tagIndex]));
        }
        // create circle dom object, return circle data
        function createCircle(tag) {
            
            var radius = window.innerWidth < 960 ? 35 : window.innerWidth * 0.026;
            var x = random(radius, bounds.width - radius);
            var y = random(radius, bounds.height - radius);

            var diameter = radius * 2;
            var circleEl = document.createElement('div');

            // need some sort of unique id...
            var id = 'circle-' + random(0, 1000, true) + '-' + Date.now();

            var circle = {
                id: id,
                radius: radius,
                position: {
                    x: random(radius, bounds.width - radius),
                    y: random(radius, bounds.height - radius)
                }
            };


            var label = document.createElement('span');
            label.innerHTML = tag;
            label.classList.add('circle-label');
            // create circle el

            circleEl.id = id;
            circleEl.style.width = diameter + 'px';
            circleEl.style.height = diameter + 'px';
            circleEl.style.borderRadius = diameter + 'px';
            circleEl.classList.add('circle');

            circleEl.appendChild(label);

            // store position for dragging
            circleEl.setAttribute('data-x', x);
            circleEl.setAttribute('data-y', y);
            circleEl.setAttribute('data-radius', radius);

            // start dragging
            circleEl.addEventListener('mousedown', function (event) {
                circlePressed(circleEl, circle, event);
            });

            containerEl.appendChild(circleEl);

            circleEls[id] = circleEl;

            return circle;
        }


        function removeRandomCircle() {
            var ids = Object.keys(circleEls);
            var idToDelete = ids[random(0, ids.length, true)];

            removeCircle(idToDelete);
        }

        // function setRandomBounds() {
        //     bounds = {
        //         width: random(200, 500, true),
        //         height: random(200, 500, true)
        //     };

        //     containerEl.style.width = bounds.width + 'px';
        //     containerEl.style.height = bounds.height + 'px';

        //     packer.setBounds(bounds);
        // }

        function removeCircle(id) {
            packer.removeCircle(id);

            requestAnimationFrame(function () {
                if (circleEls[id]){
                    containerEl.removeChild(circleEls[id]);
                    delete circleEls[id];
                }
            });
        }

        function setDamping() {
            var damping = 0.02;
            packer.setDamping(damping);
        }

        function render(circles) {
            requestAnimationFrame(function () {
                for (var id in circles) {
                    var circleEl = circleEls[id];

                    if (circleEl) {
                        var circle = circles[id];
                        var x = circle.position.x - circle.radius;
                        var y = circle.position.y - circle.radius;

                        // store position for dragging
                        circleEl.setAttribute('data-x', x);
                        circleEl.setAttribute('data-y', y);

                        // actually move the circles around
                        circleEl.style.transform = 'translateX(' + x + 'px) translateY(' + y + 'px)';
                        circleEl.classList.add('is-visible');
                    }
                }
            });
        }

        // start and stop dragging
        function circlePressed(circleEl, circle, event) {
            var circleStartPos = {
                x: parseFloat(circleEl.getAttribute('data-x')) + circle.radius,
                y: parseFloat(circleEl.getAttribute('data-y')) + circle.radius
            };

            var eventStartPos = { x: event.clientX, y: event.clientY };

            function dragStart() {
                document.addEventListener('mousemove', dragged);
                document.addEventListener('mouseup', dragEnd);
            }

            function dragged(event) {
                var currentPos = { x: event.clientX, y: event.clientY };

                var delta = {
                    x: currentPos.x - eventStartPos.x,
                    y: currentPos.y - eventStartPos.y
                };

                // start dragging if mouse moved DRAG_THRESOLD px
                if (!isDragging &&
                    (Math.abs(delta.x) > DRAG_THRESOLD || Math.abs(delta.y) > DRAG_THRESOLD)
                ) {
                    isDragging = true;
                    packer.dragStart(circle.id);
                }

                var newPos = { x: circleStartPos.x + delta.x, y: circleStartPos.y + delta.y };

                if (isDragging) {
                    // end dragging if circle is outside the bounds
                    if (
                        newPos.x < circle.radius || newPos.x > bounds.width - circle.radius ||
                        newPos.y < circle.radius || newPos.y > bounds.height - circle.radius
                    ) {
                        dragEnd();
                    } else {
                        packer.drag(circle.id, newPos);
                    }
                }
            }

            function dragEnd() {
                isDragging = false;
                document.removeEventListener('mousemove', dragged);
                packer.dragEnd(circle.id);
            }

            if (!isDragging) {
                dragStart();
            }
        }

        function random(min, max, intResult) {
            if (typeof min !== 'number' && typeof max !== 'number') {
                min = 0;
                max = 1;
            }

            if (typeof max !== 'number') {
                max = min;
                min = 0;
            }

            var result = min + Math.random() * (max - min);

            if (intResult) {
                result = parseInt(result, 10);
            }

            return result;
        }
    }

    render() {
        return (
            <div id='circles'>
                <div className="circle-wrapper">
                    <div id="circle-box">

                    </div>
                </div>
                
            </div>
        )
    }
}
export default Circles