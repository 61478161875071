import React, { Component } from 'react';
import detectBrowserLanguage from 'detect-browser-language'
import '../style/navigation.sass';
import logo from '../images/logo.svg';
import logoMion from '../images/logo-luxu.svg';
import cornerMark from '../images/corner-mark-gray.svg';
class Navigation extends Component {
  state = {
    isUpMode : false,
    percent : 0
  }
  viewPercent(current,all){
    
    this.setState({
      percent: current / all
    })

    // console.log("percent:"+ this.state.percent)
  }
  setCornerMarkDown() {
    this.setState({
      isUpMode: false
    })
    // console.log(this.state.isUpMode);
  }
  setCornerMarkTop() {
    this.setState({
      isUpMode: true
    })
    // console.log(this.state.isUpMode);
  }
  render() {
    const isUp = this.state.isUpMode ? 'is-up' : '';
    const isUpLink = this.state.isUpMode ? '#intro' : '#footer';
    const percentCount = 1000 - (this.state.percent * 65)
    
    var lang = detectBrowserLanguage();
    var cn = "zh-CN";
    // { lang == cn ? 'a' : 'b' }
    return (
      <nav className="navigation">
        <div className="navigation-wrapper o-container">
          <div className="logo-wrapper">
            <a href="#intro" data-scroll-to><img src={logo} className="ec-logo" alt="logo" /></a>
          </div>
          <div className="navigation-links-wrapper m-container o-container">
            <ul className='navigation-links'>
              <li className=""><a target="_blank" href="https://apps.apple.com/us/app/mion-sleep/id1401308399"></a></li>
              <li><a target="_blank" href={lang == cn ? 'http://misc.mionapp.com/item/help.html' : 'http://misc.mionapp.com/item/help-en.html'}></a></li>
            </ul>
          </div>
          <div className="text-logo-wrapper">
            <img src={logoMion} className="mion-text-logo" alt="logo" />

          </div>
          <div className="featuring">
            <span>Featuring: <br /><a target="_blank" href="https://mionapp.com">MION</a></span>
          </div>

          {/* <div className="main-split"></div> */}

          <div className={"corner-mark " + isUp} >
            <a href={isUpLink} className="corner-mark-link" data-scroll-to><img src={cornerMark}  className="corner-mark-image" alt="All rights reserved." width="22px" height="22px" /></a>
          </div>

          <div className="auther-info">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg">
              <circle cx="32" cy="32" strokeDashoffset={ percentCount }  r="10"/>
            </svg>

          </div>
        </div>

      </nav>
    );
  }
}

export default Navigation;